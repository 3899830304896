import React from "react"

import Layout from "../../layout/Layout";
import StyledLink from "../../components/styled-link/StyledLink";

import nenalezenoStyle from "./assets/Nenalezeno.module.scss";

import imagePlaceholder from "../../media/placeholder.jpg";

const NotFoundPage = () => {
  return (
    <Layout title="Nenalezeno">
      <section name="notfound" className={nenalezenoStyle["notfound"]}>
        <div className={nenalezenoStyle["notfound_container"]}>
          <div className={nenalezenoStyle["notfound_title"]}>
            <h1>Stránka nebyla nalezena</h1>
          </div>
          <div className={nenalezenoStyle["notfound_content"]}>
            <div className={nenalezenoStyle["notfound_description"]}>
              <p>Zadaný odkaz je neplatný nebo stránka již neexistuje.</p>
              <StyledLink to="/">Hlavní stránka</StyledLink>
            </div>
          </div>
          <div className={nenalezenoStyle["notfound_image"]}>
            <img src={imagePlaceholder} alt="Nenalezeno" />
          </div>
        </div>
      </section>
    </Layout>
  );  
};

export default NotFoundPage;
